let sliderInstagram = tns({
    'container': `.instagram-slider`,
    'items': 4,
    'mouseDrag': true,
    'arrowKeys': true,
    'navPosition': `beforeend`,
    'responsive': {
        320: {
            'items': 2,
            'gutter': 6,
        },
        575: {
            'items': 2,
            'gutter': 12,
        },
        767: {
            'items': 2,
            'gutter': 12,
        },
        992: {
            'items': 3,
            'gutter': 12,
        },
        1440: {
            'gutter': 12,
            'items': 4
        }
    }
})

let sliderMedia = tns({
    'container': `.media-carousel`,
    'items': 4,
    'mouseDrag': true,
    'arrowKeys': true,
    'navPosition': `beforeend`,
    'responsive': {
        320: {
            'items': 1,
            'nav': false,
        },
        575: {
            'items': 2,
            'gutter': 12,

        },
        992: {
            'items': 3,
            'gutter': 12,
        },
        1440: {
            'gutter': 16,
            'items': 4
        }
    }
})


let sliderTrainers = tns({
    'container': `.trainers-slider`,
    'items': 4,
    'mouseDrag': true,
    'arrowKeys': true,
    'navPosition': `beforeend`,
    'responsive': {
        320: {
            'gutter': 0,
            'items': 1,
        },
        575: {
            'gutter': 12,
            'items': 2
        },
        768: {
            'gutter': 12,
            'items': 2,
        },
        992: {
            'items': 3,
            'gutter': 12,
        },
        1440: {
            'items': 4,
            'gutter': 12,
        }
    }
})

let sliderAdvantages = tns({
    'container': `.about-slider`,
    'items': 4,
    'mouseDrag': true,
    'arrowKeys': true,
    'nav': false,
    'navPosition': `beforeend`,
    'responsive': {
        320: {
            'gutter': 0,
            'items': 1,
            'navPosition': `beforeend`,
            'nav': true
        },
        768: {
            'gutter': 12,
            'items': 2,
        },
        1200: {
            'items': 3,
            'nav': false
        },
        1440: {
            'items': 4,
            'nav': false
        }
    }
})

let bannerSlider = tns({
    'container': `.banner`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'autoplay': true,
    'autoplayTimeout': 3000,
    'speed': 400,
    'autoplayButton': false,
    'autoplayButtonOutput': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

let serviceModalSlider1 = tns({
    'container': `#serviceGalleryBox`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

let serviceModalSlider2 = tns({
    'container': `#serviceGalleryThai`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

let serviceModalSlider3 = tns({
    'container': `#serviceGalleryPersonal`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

let serviceModalSlider4 = tns({
    'container': `#serviceGalleryKids`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

let serviceModalSlider5 = tns({
    'container': `#serviceGalleryFighting`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

let serviceModalSlider6 = tns({
    'container': `#serviceGalleryGym`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

let serviceModalSlider7 = tns({
    'container': `#serviceGalleryVR`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

let serviceModalSlider8 = tns({
    'container': `#serviceGalleryGroup`,
    'items': 1,
    'mouseDrag': true,
    'arrowKeys': true,
    'controls': false,
    'navPosition': `beforeend`,
    'responsive': {
        0: {
            'controls': true
        },
        768: {
            'controls': false
        },
    }
})

// GoogleMaps init

function initMap() {
    const gym = {lat: 55.7131592, lng: 37.5582862}
    const map = new google.maps.Map(document.getElementById(`map`), {
        zoom: 17,
        center: gym,
        styles: [
            {
                'featureType': `water`,
                'elementType': `geometry`,
                'stylers': [
                    {
                        'color': `#e9e9e9`
                    },
                    {
                        'lightness': 17
                    }
                ]
            },
            {
                'featureType': `landscape`,
                'elementType': `geometry`,
                'stylers': [
                    {
                        'color': `#f5f5f5`
                    },
                    {
                        'lightness': 20
                    }
                ]
            },
            {
                'featureType': `road.highway`,
                'elementType': `geometry.fill`,
                'stylers': [
                    {
                        'color': `#ffffff`
                    },
                    {
                        'lightness': 17
                    }
                ]
            },
            {
                'featureType': `road.highway`,
                'elementType': `geometry.stroke`,
                'stylers': [
                    {
                        'color': `#ffffff`
                    },
                    {
                        'lightness': 29
                    },
                    {
                        'weight': 0.2
                    }
                ]
            },
            {
                'featureType': `road.arterial`,
                'elementType': `geometry`,
                'stylers': [
                    {
                        'color': `#ffffff`
                    },
                    {
                        'lightness': 18
                    }
                ]
            },
            {
                'featureType': `road.local`,
                'elementType': `geometry`,
                'stylers': [
                    {
                        'color': `#ffffff`
                    },
                    {
                        'lightness': 16
                    }
                ]
            },
            {
                'featureType': `poi`,
                'elementType': `geometry`,
                'stylers': [
                    {
                        'color': `#f5f5f5`
                    },
                    {
                        'lightness': 21
                    }
                ]
            },
            {
                'featureType': `poi.park`,
                'elementType': `geometry`,
                'stylers': [
                    {
                        'color': `#dedede`
                    },
                    {
                        'lightness': 21
                    }
                ]
            },
            {
                'elementType': `labels.text.stroke`,
                'stylers': [
                    {
                        'visibility': `on`
                    },
                    {
                        'color': `#ffffff`
                    },
                    {
                        'lightness': 16
                    }
                ]
            },
            {
                'elementType': `labels.text.fill`,
                'stylers': [
                    {
                        'saturation': 36
                    },
                    {
                        'color': `#333333`
                    },
                    {
                        'lightness': 40
                    }
                ]
            },
            {
                'elementType': `labels.icon`,
                'stylers': [
                    {
                        'visibility': `off`
                    }
                ]
            },
            {
                'featureType': `transit`,
                'elementType': `geometry`,
                'stylers': [
                    {
                        'color': `#f2f2f2`
                    },
                    {
                        'lightness': 19
                    }
                ]
            },
            {
                'featureType': `administrative`,
                'elementType': `geometry.fill`,
                'stylers': [
                    {
                        'color': `#fefefe`
                    },
                    {
                        'lightness': 20
                    }
                ]
            },
            {
                'featureType': `administrative`,
                'elementType': `geometry.stroke`,
                'stylers': [
                    {
                        'color': `#fefefe`
                    },
                    {
                        'lightness': 17
                    },
                    {
                        'weight': 1.2
                    }
                ]
            }
        ]
    })

    let contentString = `<div id="content">` +
        `<div id="siteNotice">` +
        `</div>` +
        `<h1 id="firstHeading" class="firstHeading">Академия бокса</h1>` +
        `<div id="bodyContent">` +
        `<p>Адрес: г. Москва, ул. Лужники д. 24, строение 4</p>` +
        `<p>Телефон: +7 (495) 507-74-79</p>` +
        `<p>Email: info@boxing-gym.ru</p><br/>` +
        `</div>` +
        `</div>`

    let infowindow = new google.maps.InfoWindow({
        content: contentString
    })

    let marker = new google.maps.Marker({
        position: gym,
        map: map,
        title: `Boxing Academy`,
        icon: {
            url: `/img/pointer.svg`,
            scaledSize: new google.maps.Size(32, 48)
        }
    })

    marker.addListener(`click`, function () {
        infowindow.open(map, marker)
    })

}

// Panorama360deg
/*
viewer = pannellum.viewer(`panorama`, {
    'default': {
        'mouseZoom': false,
        'firstScene': `one`,
        'sceneFadeDuration': 1500,
        'yaw': 0.0,
        'pitch': 0.0,
        'compass': true
    },

    'scenes': {
        'one': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-1.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 5.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': `Вперед в зал`,
                    'sceneId': `two`
                },
            ]
        },

        'two': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-2.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': -5.0,
                    'yaw': -210.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': `Вернуться ко входу`,
                    'sceneId': `one`
                },
                {
                    'pitch': 0.0,
                    'yaw': -20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': `В основной зал`,
                    'sceneId': `three`
                }
            ]
        },

        'three': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-3.jpg`,
            'autoLoad': true,
            'pitch': -5.0,
            'yaw': -40.0,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 10.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': `Вернуться назад`,
                    'sceneId': `two`
                },
                {
                    'pitch': -15.0,
                    'yaw': -70.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': `Войти`,
                    'sceneId': `four`
                },
            ]
        },

        'four': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-4.jpg`,
            'autoLoad': true,
            'pitch': -10.0,
            'yaw': 240.0,
            'hotSpots': [
                {
                    'pitch': -7.0,
                    'yaw': 165.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': `Вернуться назад`,
                    'sceneId': `three`
                },
                {
                    'pitch': -12.0,
                    'yaw': 220.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': `Подняться на ринг`,
                    'sceneId': `seven`
                },
                {
                    'pitch': -8.0,
                    'yaw': 250.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': `Пройти вглубь зала`,
                    'sceneId': `six`
                },
            ]
        },

        'five': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-5.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `four`
                },
                {
                    'pitch': 0.0,
                    'yaw': 20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `six`
                },
            ]
        },

        'six': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-6.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `five`
                },
                {
                    'pitch': 0.0,
                    'yaw': 20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `seven`
                },
            ]
        },

        'seven': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-7.jpg`,
            'autoLoad': true,
            'pitch': 0.0,
            'yaw': 40.0,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `six`
                },
                {
                    'pitch': 0.0,
                    'yaw': 20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `eight`
                },
            ]
        },

        'eight': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-8.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `seven`
                },
                {
                    'pitch': 0.0,
                    'yaw': 20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `nine`
                },
            ]
        },

        'nine': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-9.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `eight`
                },
                {
                    'pitch': 0.0,
                    'yaw': 20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `ten`
                },
            ]
        },

        'ten': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-10.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `nine`
                },
                {
                    'pitch': 0.0,
                    'yaw': 20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `eleven`
                },
            ]
        },

        'eleven': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-11.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `ten`
                },
                {
                    'pitch': 0.0,
                    'yaw': 20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `twelve`
                },
            ]
        },

        'twelve': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-12.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `eleven`
                },
                {
                    'pitch': 0.0,
                    'yaw': 20.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `thirteen`
                },
            ]
        },

        'thirteen': {
            'type': `equirectangular`,
            'panorama': `/img/panorama-13.jpg`,
            'autoLoad': true,
            'hotSpots': [
                {
                    'pitch': 0.0,
                    'yaw': 0.0,
                    'type': `scene`,
                    'cssClass': `custom-hotspot`,
                    'text': ``,
                    'sceneId': `twelve`
                },
            ]
        },

    }
})
*/

// Menu opener

function toggleMenu() {
    let menu = document.querySelector(`.aside-menu-content`)
    let body = document.querySelector(`body`)
    let opener = document.querySelector(`.aside-menu-btn`)
    if (menu.classList.contains(`active`)) {
        menu.classList.remove(`active`)
        body.classList.remove(`faded-content`)
        opener.classList.remove(`active`)
    } else {
        body.classList.add(`faded-content`)
        menu.classList.add(`active`)
        opener.classList.add(`active`)
    }
}

// Trainer opener

document.addEventListener(`DOMContentLoaded`, function () {

    let opener = document.querySelectorAll(`.trainers-slide-opener`)

    opener.forEach(function (item) {
        item.addEventListener(`click`, function (e) {
            e.preventDefault()
            let trainer = this.closest(`.trainers-slide`)
            if (trainer.classList.contains(`active`)) {
                trainer.classList.remove(`active`)
                this.classList.remove(`opened`)
            } else {
                trainer.classList.add(`active`)
                this.classList.add(`opened`)
            }
        })
    })
})


// Modals

document.addEventListener(`DOMContentLoaded`, function () {

    let modalButtons = document.querySelectorAll(`.js-open-modal`),
        overlay = document.querySelector(`.js-overlay-modal`),
        closeButtons = document.querySelectorAll(`.js-modal-close`),
        body = document.querySelector(`body`)

    modalButtons.forEach(function (item) {
        item.addEventListener(`click`, function (e) {
            e.preventDefault()
            let modalActive = document.querySelector(`.modal.active`)
            if (modalActive)
                modalActive.classList.remove(`active`)
            body.classList.remove(`faded-content`)
            let modalId = this.getAttribute(`data-modal`),
                modalElem = document.querySelector(`.modal[data-modal="` + modalId + `"]`)
            modalElem.classList.add(`active`)
            overlay.classList.add(`active`)
            body.classList.add(`faded-content`)
        })
    })

    closeButtons.forEach(function (item) {
        item.addEventListener(`click`, function (e) {
            let parentModal = this.closest(`.modal`)
            parentModal.classList.remove(`active`)
            overlay.classList.remove(`active`)
            body.classList.remove(`faded-content`)
        })
    })

    document.body.addEventListener(`keyup`, function (e) {
        let key = e.keyCode
        if (key === 27) {
            document.querySelector(`.modal.active`).classList.remove(`active`)
            document.querySelector(`.overlay`).classList.remove(`active`)
            document.querySelector(`body`).classList.remove(`faded-content`)

        }
    }, false)

    overlay.addEventListener(`click`, function () {
        document.querySelector(`.modal.active`).classList.remove(`active`)
        document.querySelector(`body`).classList.remove(`faded-content`)
        this.classList.remove(`active`)
    })
})

function controlHeight() {
    let heightWindow = document.documentElement.clientHeight,
        asideMenu = document.querySelector('.aside-menu-content')

    if (heightWindow < 800) {
        asideMenu.classList.add('smaller-screen')
    } else {
        asideMenu.classList.remove('smaller-screen')
    }
}

document.addEventListener('DOMContentLoaded', function () {
    controlHeight();
    InstagramModal();
});

window.addEventListener(`resize`, event => {
    controlHeight();
}, false);


/* Модальное окно истаграма */
function InstagramModal() {

    let links = document.querySelectorAll('.instagram-slide-link'),
        overlay = document.querySelector(`.js-overlay-modal`),
        body = document.querySelector(`body`)

    for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', function (e) {
            e.preventDefault()

            let modal = document.querySelector('.modal-instagram')

            modal.classList.add('active')
            this.classList.add('active')
            body.classList.add('faded-content')

            let imgSrc = this.closest('.instagram-slide').querySelector('.instagram-slide-photo').src,
                text = this.closest('.instagram-slide').querySelector('.instagram-slide-text').innerHTML

            modal.querySelector('.modal-image').src = imgSrc
            modal.querySelector('.modal-text').innerHTML = text
        })
    }
}


// Отправка на Jquery

$(document).ready(function () {
    $("form#trial-training-modal-form").submit(function () {
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php",
            data: th.serialize()
        }).done(function () {
            alert("Спасибо за Вашу заявку!");
            setTimeout(function () {
                th.trigger("reset");
            }, 1000);
        });
        return false;
    });

    $("form#callback-modal-form").submit(function () {
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php",
            data: th.serialize()
        }).done(function () {
            alert("Спасибо за Вашу заявку!");
            setTimeout(function () {
                th.trigger("reset");
            }, 1000);
        });
        return false;
    });

    $("form#club-card-modal-form").submit(function () {
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php",
            data: th.serialize()
        }).done(function () {
            alert("Спасибо за Вашу заявку!");
            setTimeout(function () {
                th.trigger("reset");
            }, 1000);
        });
        return false;
    });

    $("form#trial-trainig-form").submit(function () {
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php",
            data: th.serialize()
        }).done(function () {
            alert("Спасибо за Вашу заявку!");
            setTimeout(function () {
                th.trigger("reset");
            }, 1000);
        });
        return false;
    });

});
